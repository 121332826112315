"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.postProveUpdatedPreFillData = exports.getProvePreFill = exports.getProveTransactionStatus = exports.postProveAuthLinkClicked = exports.postProveSendAuthLink = exports.getProveAvail = void 0;
const config_1 = require("config");
// Get - ProveAvail
const getProveAvail = async () => {
    return await fetch(`${config_1.REACT_APP_API_URL}identity/v1/prove/available`);
};
exports.getProveAvail = getProveAvail;
// Post - postProveSendAuthLink
const postProveSendAuthLink = async ({ phone, ssn_last_4, credit_application_id, location_id, }) => {
    return await fetch(`${config_1.REACT_APP_API_URL}identity/v1/prove/send_auth_link`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            phone_number: phone,
            ssn_last_4,
            credit_application_id,
            location_id,
        }),
    });
};
exports.postProveSendAuthLink = postProveSendAuthLink;
// Post - postProveAuthLinkClicked
const postProveAuthLinkClicked = async ({ vfp }) => {
    return await fetch(`${config_1.REACT_APP_API_URL}identity/v1/prove/auth_link_clicked`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            vfp,
        }),
    });
};
exports.postProveAuthLinkClicked = postProveAuthLinkClicked;
// Get - Status
const getProveTransactionStatus = async (sessionJson) => {
    if (Object.keys(sessionJson).length > 0) {
        return await fetch(`${config_1.REACT_APP_API_URL}identity/v1/prove/transaction_status?phone_number=${sessionJson.phone_number}&ssn_last_4=${sessionJson.ssn_last_4}&session_id=${sessionJson.session_id}`);
    }
};
exports.getProveTransactionStatus = getProveTransactionStatus;
// Get - PreFill
const getProvePreFill = async (sessionJson) => {
    if (Object.keys(sessionJson).length > 0) {
        return await fetch(`${config_1.REACT_APP_API_URL}identity/v1/prove/pre_fill?phone_number=${sessionJson.phone_number}&ssn_last_4=${sessionJson.ssn_last_4}&session_id=${sessionJson.session_id}`);
    }
};
exports.getProvePreFill = getProvePreFill;
// Post - postProveUpdatedPreFillData
const postProveUpdatedPreFillData = async ({ session_id, pre_fill_data, }) => {
    return await fetch(`${config_1.REACT_APP_API_URL}identity/v1/prove/updated_pre_fill_data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            session_id,
            pre_fill_data,
        }),
    });
};
exports.postProveUpdatedPreFillData = postProveUpdatedPreFillData;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
const utils_1 = require("utils");
const react_router_dom_1 = require("react-router-dom");
const config_1 = require("config");
const SendReceiptExtraInfo = () => {
    return ((0, jsx_runtime_1.jsxs)("div", { className: "send-receipt-extra-info", children: [(0, jsx_runtime_1.jsxs)("div", { className: "attention-paragraph", children: [(0, jsx_runtime_1.jsx)("span", { className: "icon-text-attention", role: "img", "aria-label": "Attention", children: "!" }), (0, jsx_runtime_1.jsxs)("p", { children: ["Funding will be processed upon completion of the digital signature by the patient.", ' '] })] }), (0, jsx_runtime_1.jsxs)("div", { className: "attention-paragraph", children: [(0, jsx_runtime_1.jsx)("span", { className: "icon-text-attention", role: "img", "aria-label": "Attention", children: "!" }), (0, jsx_runtime_1.jsx)("p", { children: "If the Patient does not complete the digital signature in 10 days, the transaction will be voided." })] })] }));
};
const SendReceiptModalContent = ({ handleCommunicationMethod, setConfirmSMS, formSubmitted, name, sentTo, communicationMethod, onGoToDashboard, onRequestClose, confirmSMS, fields, email, phone, radioVal, handleSendReceipt, submitting, alphaeonAccountNumber, }) => {
    const history = (0, react_router_dom_1.useHistory)();
    // const [localFields, setLocalFields] = useState<
    //   { key: string; value: string }[]
    // >([])
    // Func submit form
    const formSubmit = (values) => {
        if (values) {
            handleSendReceipt();
        }
    };
    // Func validate form
    const formValidate = (values) => {
        if (values) {
            return undefined;
        }
    };
    // Effect init
    // useEffect(() => {
    //   setLocalFields(fields)
    //   return () => {
    //     setLocalFields([])
    //   }
    // }, [])
    return ((0, jsx_runtime_1.jsx)("div", { children: formSubmitted && sentTo ? ((0, jsx_runtime_1.jsxs)("div", { className: "send-receipt-modal-content", children: [(0, jsx_runtime_1.jsx)("h4", { children: "THANK YOU!" }), (0, jsx_runtime_1.jsx)("div", { className: "__communication-method-description", children: (0, jsx_runtime_1.jsxs)("p", { children: ["We have sent the digital receipt to ", (0, jsx_runtime_1.jsx)("br", {}), ' ', ` ${name.firstName} ${name.lastName}`, " at", ' ', communicationMethod === 'sms'
                                ? (0, utils_1.formatPhoneNumber)(sentTo)
                                : sentTo, "."] }) }), (0, jsx_runtime_1.jsx)(SendReceiptExtraInfo, {}), (0, jsx_runtime_1.jsxs)("div", { className: "send-receipt-buttons", children: [(0, jsx_runtime_1.jsx)(components_1.Button, { className: "button-warn", type: "button", onClick: onGoToDashboard, children: "GO TO DASHBOARD" }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: "button-primary button-warn-outline", onClick: () => history.push(`${config_1.ACCOUNT_DETAIL_URL}${alphaeonAccountNumber}`), children: "VIEW ACCOUNT SUMMARY" })] })] })) : ((0, jsx_runtime_1.jsxs)("div", { className: "send-receipt-modal-content", children: [(0, jsx_runtime_1.jsx)("h4", { children: "SEND DIGITAL RECEIPT TO PATIENT" }), (0, jsx_runtime_1.jsx)("div", { className: "__communication-method-description", children: (0, jsx_runtime_1.jsxs)("p", { children: ["Please select the preferred method of communication for", ` ${name.firstName} ${name.lastName}`, ". Their response is needed to complete the transaction.", ' '] }) }), (0, jsx_runtime_1.jsx)("div", { className: "send-receipt-form", children: (0, jsx_runtime_1.jsx)(react_final_form_1.Form, { initialValues: {
                            communicationMethod: radioVal,
                        }, onSubmit: formSubmit, validate: formValidate, children: ({ handleSubmit }) => {
                            // Button Disabled Bool
                            const btnDisabled = submitting ||
                                radioVal == undefined ||
                                (radioVal === 'sms' && confirmSMS !== true)
                                ? true
                                : false;
                            return ((0, jsx_runtime_1.jsxs)("form", { onSubmit: handleSubmit, "data-testid": "send-receipt-form", children: [(0, jsx_runtime_1.jsx)("fieldset", { className: "radioInput", onChange: handleCommunicationMethod, children: fields && fields.length >= 1 ? ((0, jsx_runtime_1.jsx)("div", { children: fields.map((field, idx) => field && ((0, jsx_runtime_1.jsx)("div", { className: "input-wrapper", children: (0, jsx_runtime_1.jsxs)("label", { children: [(0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: "communicationMethod", label: false, component: components_1.InputRadio, type: "radio", value: field.value, "data-testid": field.value + '-option' }), ' ', field.key, (0, jsx_runtime_1.jsx)("span", { className: `${field.key === 'Email'
                                                                ? 'email-input'
                                                                : ''}`, children: field.value === 'email'
                                                                ? email
                                                                : (0, utils_1.formatPhoneNumber)(phone) })] }) }, `fields-${idx}`))) })) : null }), radioVal === 'sms' ? ((0, jsx_runtime_1.jsxs)("div", { className: "sms-extra-info", children: [(0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("h3", { children: "Please read this to the Patient:" }), (0, jsx_runtime_1.jsxs)("p", { children: ["\"Please confirm that ", (0, utils_1.formatPhoneNumber)(phone), " is an accurate phone number and that you wish to receive this communication by SMS\""] })] }), (0, jsx_runtime_1.jsx)("span", { children: "If the patient aggress, please check the box below." })] })) : null, (0, jsx_runtime_1.jsx)(SendReceiptExtraInfo, {}), radioVal === 'sms' ? ((0, jsx_runtime_1.jsx)("div", { className: "sms-extra-checkbox", children: (0, jsx_runtime_1.jsxs)("label", { children: [(0, jsx_runtime_1.jsx)("input", { type: "checkbox", name: "confirm_sms_agreement", onChange: (e) => setConfirmSMS(e.target.checked), value: confirmSMS ? 'checked' : '', "data-testid": "confirm-sms-agreement" }), (0, jsx_runtime_1.jsx)("p", { children: "By checking this box, you confirm the customer has provide an accurate phone number and indicated they wish to receive this communication by SMS." })] }) })) : null, (0, jsx_runtime_1.jsxs)("div", { className: "send-receipt-buttons", children: [(0, jsx_runtime_1.jsx)(components_1.Button, { type: "submit", className: `send-receipt-modal-btn button-primary button-warn ${btnDisabled ? 'is-disabled' : 'is-enabled'}`, "aria-disabled": btnDisabled && !submitting, children: submitting ? '...Submitting' : 'Submit' }), (0, jsx_runtime_1.jsx)(components_1.Button, { className: "button-primary cancel-button", type: "button", onClick: onRequestClose, children: "CANCEL" })] })] }));
                        } }) })] }, `send-receipt-modal`)) }));
};
exports.default = SendReceiptModalContent;

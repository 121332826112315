"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_final_form_1 = require("react-final-form");
const utils_1 = require("utils");
const internal_portal_components_1 = require("internal-portal-components");
function SearchByPhoneForm({ handleSubmit, loading, phoneNumber, }) {
    const onSubmit = (values) => {
        handleSubmit(values.phoneNumber.replace(/[^\d]/g, ''));
    };
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(react_final_form_1.Form, { onSubmit: onSubmit, initialValues: {
                phoneNumber: phoneNumber ? (0, utils_1.parsePhoneNumber)(phoneNumber) : '',
            }, render: ({ handleSubmit, hasValidationErrors }) => ((0, jsx_runtime_1.jsxs)("form", { onSubmit: handleSubmit, className: "phone-input-container", children: [(0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: "phoneNumber", validate: (0, utils_1.composeValidators)(utils_1.validateRequiredField, (0, utils_1.validateLength)(14, 14, 'Expected format should be (XXX) XXX-XXXX')), parse: utils_1.parsePhoneNumber, children: ({ input, meta }) => ((0, jsx_runtime_1.jsxs)("div", { className: "input-and-label", children: [(0, jsx_runtime_1.jsx)(react_1.CFormLabel, { htmlFor: "phoneNumber", className: meta.error && meta.touched ? 'invalid' : '', children: "Phone Number" }), (0, jsx_runtime_1.jsx)(react_1.CFormInput, { ...input, className: meta.error && meta.touched ? 'invalid' : '', placeholder: "(XXX) XXX-XXXX", "data-testid": "search-by-phone-phone-input" }), meta.error && meta.touched ? ((0, jsx_runtime_1.jsx)("span", { className: "error", children: meta.error })) : ((0, jsx_runtime_1.jsx)("span", { className: "error" }))] })) }), (0, jsx_runtime_1.jsx)(internal_portal_components_1.SubmitButton, { color: "primary", disabled: hasValidationErrors || loading, loading: loading, dataTestID: "search-by-phone-submit-button", children: "Search" })] })) }) }));
}
exports.default = SearchByPhoneForm;

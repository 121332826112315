"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const merchant_portal_components_1 = require("merchant-portal-components");
const utils_1 = require("utils");
const lodash_1 = require("lodash");
const lp_components_1 = require("lp-components");
const formatToFixedTwoDecimals = (number) => {
    if (typeof number == 'number') {
        return number;
    }
    else if (typeof number == 'string' && number == '') {
        return 0;
    }
    else if (typeof number == 'string') {
        return (0, utils_1.toAmount)(number);
    }
    else {
        return 0;
    }
};
const validateMinAmountValue = (number) => {
    const numberString = typeof number == 'number' ? number.toString() : number;
    return numberString == '' || /^\d+$/.test(numberString);
};
const formatMDF = (merchantDiscountRate) => {
    return (merchantDiscountRate * 100).toFixed(2);
};
function PlanSettingsTable({ plans, onChange }) {
    const [planSettings, setPlanSettings] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        plans && setPlanSettings((0, lodash_1.cloneDeep)(plans));
    }, [plans]);
    if (plans == undefined)
        return (0, jsx_runtime_1.jsx)("span", {});
    const locPlanSettingsChange = (value, id, key) => {
        const index = planSettings?.findIndex((plan) => id == plan.id);
        if (index == undefined)
            return (0, jsx_runtime_1.jsx)("span", {});
        const plan = planSettings[index];
        const planChanges = (0, lodash_1.cloneDeep)(plan);
        planChanges[key] = value;
        const newPlanSettings = [...planSettings];
        newPlanSettings[index] = planChanges;
        setPlanSettings(newPlanSettings);
        const planTypeChanges = planChanges;
        onChange(planTypeChanges);
    };
    return ((0, jsx_runtime_1.jsxs)(lp_components_1.Table, { data: planSettings, children: [(0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "name", label: "Plan Term", className: "planName", style: { width: '100%' }, component: ({ data }) => {
                    if (data.name == 'REVOLVING') {
                        return ((0, jsx_runtime_1.jsxs)("td", { children: ["Revolving Plan, ", formatMDF(data.merchantDiscountRate), "% MDF"] }));
                    }
                    else {
                        return ((0, jsx_runtime_1.jsxs)("td", { children: [data.name.trim(), ", ", formatMDF(data.merchantDiscountRate), "% MDF"] }));
                    }
                } }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "dashboardEnabled", label: "Dashboard / Calculator", component: ({ data }) => {
                    if (data.name != 'REVOLVING') {
                        return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("td", { children: [' ', (0, jsx_runtime_1.jsx)(lp_components_1.Switch, { label: false, uncheckedIcon: (0, jsx_runtime_1.jsx)("div", { style: {
                                                color: 'white',
                                                paddingLeft: '4px',
                                                paddingTop: '8px',
                                                fontFamily: 'inherit',
                                                fontSize: '11px',
                                            }, children: "OFF" }), checkedIcon: (0, jsx_runtime_1.jsx)("div", { style: {
                                                color: 'white',
                                                paddingLeft: '7px',
                                                paddingTop: '8px',
                                                fontFamily: 'inherit',
                                                fontSize: '11px',
                                            }, children: "ON" }), input: {
                                            name: 'dashboardEnabled',
                                            value: data.dashboardEnabled,
                                        }, onChange: (value) => locPlanSettingsChange(value, data.id, 'dashboardEnabled'), meta: {} })] }) }));
                    }
                    else {
                        return (0, jsx_runtime_1.jsx)("td", { children: "DEFAULT PLAN" });
                    }
                } }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "minPurchaseAmount", label: "Minimum Amount", component: ({ data }) => {
                    if (data.name != 'REVOLVING') {
                        return ((0, jsx_runtime_1.jsx)(merchant_portal_components_1.EditableInput, { initialValue: data.minPurchaseAmount, onSave: (value) => locPlanSettingsChange(value, data.id, 'minPurchaseAmount'), prefix: "$", inputType: "text", inputValidator: validateMinAmountValue, inputFormatter: formatToFixedTwoDecimals }));
                    }
                    else {
                        return (0, jsx_runtime_1.jsx)("td", {});
                    }
                } }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "maxPurchaseAmount", label: "Maximum Amount", component: ({ data }) => {
                    if (data.name != 'REVOLVING') {
                        return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("td", { children: ["$", data.maxPurchaseAmount] }) }));
                    }
                    else {
                        return (0, jsx_runtime_1.jsx)("td", {});
                    }
                } })] }));
}
exports.default = react_1.default.memo(PlanSettingsTable);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processStatus = void 0;
// Process Status
const processStatus = (postRes) => {
    if (postRes) {
        if (postRes.status === 'verified') {
            return 'verified';
        }
        else if (postRes.status === 'failed') {
            return 'failed';
        }
        else {
            return false; // Continue Polling
        }
        // if (postRes.clicked === false) {
        //   return false // Continue Polling
        // }
        // if (postRes.clicked === true && postRes.verified === true) {
        //   return '/credit-portal/prove/apply' // Go to pre-fill
        // } else if (postRes.clicked === true && postRes.verified === false) {
        //   return '/credit-portal/apply/wizard' // Go to legacy application
        // }
    }
};
exports.processStatus = processStatus;

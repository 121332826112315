"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPhoneNumber = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    phoneNumber: '',
};
const slice = (0, toolkit_1.createSlice)({
    name: 'globalSearch',
    initialState,
    reducers: {
        setPhoneNumber: (state, action) => {
            state.phoneNumber = action.payload;
        },
    },
});
exports.setPhoneNumber = slice.actions.setPhoneNumber;
exports.default = slice.reducer;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_number_format_1 = require("react-number-format");
const InputText = ({ input, meta, ...rest }) => {
    const { label = '', placeholder = '', required, requiredIndicator = ' *', isAllowed = {}, } = rest;
    return ((0, jsx_runtime_1.jsxs)("fieldset", { "data-testid": `fieldset-${input.name}`, className: `${input.className ? input.className : ''} ${meta.error && meta.pristine === false ? 'error-fieldset' : ''}`, children: [(0, jsx_runtime_1.jsx)("span", { children: (0, jsx_runtime_1.jsxs)("label", { "data-testid": `label-${input.name}`, htmlFor: input.name, children: [label, required && ((0, jsx_runtime_1.jsx)("span", { className: "required-indicator", "aria-hidden": "true", children: requiredIndicator })), rest.tooltip ? (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: rest.tooltip }) : null] }) }), (0, jsx_runtime_1.jsx)("div", { className: "input-wrapper", children: (0, jsx_runtime_1.jsx)(react_number_format_1.NumericFormat, { ...input, ...rest, name: input.name, allowLeadingZeros: true, "data-testid": `input-${input.name}`, placeholder: placeholder, isAllowed: isAllowed, type: "tel", renderText: (value) => (0, jsx_runtime_1.jsx)("b", { children: value }) }) }), (meta.error || meta.submitError) && meta.touched && ((0, jsx_runtime_1.jsx)("span", { className: "error-message", children: meta.error || meta.submitError }))] }));
};
exports.default = InputText;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_imask_1 = require("react-imask");
// import { FieldRenderProps } from 'react-final-form'
// type Props = FieldRenderProps<string, any>
const InputMask = ({ input, meta, ...rest }) => {
    const { label = '', 
    // placeholder = '',
    required, requiredIndicator = ' *', } = rest;
    // https://www.npmjs.com/package/react-imask
    // use ref to get access to internal "masked = ref.current.maskRef"
    // const ref = React.useRef(null);
    // const inputRef = React.useRef(null);
    return ((0, jsx_runtime_1.jsxs)("fieldset", { "data-testid": `fieldset-${input.name}`, className: `${input.className ? input.className : undefined}`, children: [(0, jsx_runtime_1.jsx)("span", { children: (0, jsx_runtime_1.jsxs)("label", { "data-testid": `label-${input.name}`, htmlFor: input.name, children: [label, required && ((0, jsx_runtime_1.jsx)("span", { className: "required-indicator", "aria-hidden": "true", children: requiredIndicator }))] }) }), (0, jsx_runtime_1.jsx)("div", { className: "input-wrapper", children: (0, jsx_runtime_1.jsx)(react_imask_1.IMaskInput, { placeholder: input.placeholder ?? '', ...input, ...rest, "data-testid": `input-${input.name}` }) }), (meta.error || meta.submitError) && meta.touched && ((0, jsx_runtime_1.jsx)("span", { className: "error-message", children: meta.error || meta.submitError }))] }));
};
exports.default = InputMask;
